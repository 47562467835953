<template>
  <div class="perfromRank frame">
    <div class="BugNav">
      <div class="navTag">
        <div v-for="item in Nav_Array" :key="item.index" :class="item.index === Nav_index ? 'nav_item nav_active' : 'nav_item'">{{ item.label }}</div>
      </div>
    </div>
    <div class="bugDeviceItem" v-for="(item, index) in Ranklist" :key="index">
      <div class="icon"></div>
      <div class="name">
        <div ref="isOverflow">{{ item.name }}</div>
      </div>
      <div class="deviceValue">{{ item.value }}</div>
    </div>
  </div>
</template>
<script>
import { msFormatter } from '@/axios/utils.js';
export default {
  data() {
    return {
      Nav_index: 1,
      Nav_Array: [
        {
          label: '时长',
          index: 0,
          color: '#f3e5b0'
        },
        {
          label: '次数',
          index: 1,
          color: '#5dba37'
        }
      ],
      Ranklist: []
    };
  },
  mounted() {
    this.init();
    setInterval(() => {
      this.init();
    }, 120 * 1000);
  },
  methods: {
    async init() {
      this.Ranklist.length = 0;
      this.Nav_index++;
      if (this.Nav_index === 2) {
        this.Nav_index = 0;
      }
      let res;
      switch (this.Nav_index) {
        case 0:
          res = await this.$apis.LSD.bugTimer({ topN: 7 });
          if (res) {
            res.xaxisValues.forEach((item, index) => {
              this.Ranklist.push({ name: item, value: msFormatter(res.yaxisValues[index]) });
            });
          }
          break;
        case 1:
          res = await this.$apis.LSD.bugNumber({ topN: 7 });
          if (res) {
            res.xaxisValues.forEach((item, index) => {
              this.Ranklist.push({ name: item, value: res.yaxisValues[index] });
            });
          }
          break;
      }

      this.$nextTick(() => {
        let isOverflow = this.$refs.isOverflow;
        if (isOverflow) {
          isOverflow.forEach(item => {
            item.className = '';
            let cWidth = item.clientWidth;
            let sWidth = item.scrollWidth;
            if (sWidth > cWidth) {
              item.className = 'nameRoll';
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.BugNav {
  height: 50px;
  margin: 20px 0px -10px 10px;
  display: flex;
  flex-direction: row-reverse;
}
.navTag {
  width: 150px;
  margin-top: 5px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
}
.nav_item {
  width: 54px;
  height: 26px;
  line-height: 21px;
  text-align: center;
  color: rgba(41, 245, 255, 0.26);
  border: 1px solid rgba(39, 250, 255, 0.3);
  background-color: #111111;
  padding: 2px;
}
.nav_active {
  width: 54px;
  height: 26px;
  line-height: 21px;
  background-color: #001732;
  border: 1px solid #27faff;
  padding: 2px;
  color: rgba(39, 250, 255, 1);
}
.bugDeviceItem {
  display: flex;
  justify-content: space-between;
  width: 182px;
  height: 32px;
  background: #072846;
  border-top: 2px solid rgba(67, 186, 254, 0.5);
  box-sizing: border-box;
  margin-bottom: 18px;
  margin-left: 5px;
  line-height: 32px;
  padding-right: 5px;
}

.deviceValue {
  flex: 0.8;
  text-align: end;
  font-family: MyFontName;
  color: rgba(39, 250, 255, 1);
}
.name {
  width: 75px;
  color: #fefefe;
  margin-right: 0px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
}
.name > .nameRoll {
  width: 120px;
  animation: move 10s linear;
  animation-iteration-count: infinite;
}
@keyframes move {
  0% {
    transform: translateX(0px);
  }
  10% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-130px);
  }
}
</style>
