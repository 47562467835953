<template>
  <div class="deviceOee">
    <div class="deviceOeeBorder frame">
      <div class="lineCharts"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { Oee, availability, quality, performance } from './iconBase64';
export default {
  data() {
    return {};
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.lineCharts');
      let myChart = echarts.init(chartDom);
      myChart.clear();
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            params.forEach(item => {
              str = str.concat(`${item.marker + item.seriesName}:${((item.value * 1000) / 1000).toFixed(2)}%<br/>`);
            });
            return str;
          }
        },
        grid: {
          top: '20%',
          right: '3%',
          left: '6%',
          bottom: '20%'
        },
        legend: {
          data: [
            { name: '效率', icon: `image://${Oee}` },
            { name: '可用', icon: `image://${availability}` },
            { name: '质量', icon: `image://${quality}` },
            { name: '表现', icon: `image://${performance}` }
          ],
          top: '5%',
          right: '5%',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#F3F3F3'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.ChartsData.xaxisValues,
          axisLabel: {
            show: true,
            interval: 0,
            padding: [4, 0, 0, 0],
            textStyle: {
              color: '#FEFEFE'
            }
          },
          axisTick: {
            show: false,
            onGap: 2
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: 'rgba(67, 186, 254, .05)'
            }
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          formatter: '{value} %',
          name: '百分比%',
          nameTextStyle: {
            color: '#FEFEFE'
          },
          max: 100,
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(67, 186, 254, .1)'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#FEFEFE'
            }
          }
        },
        series: [
          {
            name: '效率',
            type: 'line',
            data: this.ChartsData.oee,
            symbolSize: 14,
            symbol: `image://${Oee}`,
            itemStyle: {
              color: '#FFF0B9',
              borderColor: '#fff',
              borderWidth: 10
            }
          },
          {
            name: '可用',
            type: 'line',
            data: this.ChartsData.availability,
            symbolSize: 14,
            symbol: `image://${availability}`,
            itemStyle: {
              color: '#F39800',
              borderColor: '#fff'
            }
          },
          {
            name: '质量',
            type: 'line',
            data: this.ChartsData.quality,
            symbolSize: 14,
            symbol: `image://${quality}`,
            itemStyle: {
              color: '#5EBB38',
              borderColor: '#fff'
            }
          },
          {
            name: '表现',
            type: 'line',
            data: this.ChartsData.performance,
            symbolSize: 14,
            symbol: `image://${performance}`,
            itemStyle: {
              color: '#438EDB',
              borderColor: '#fff'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.lineCharts {
  width: 100%;
  height: 100%;
}
</style>
