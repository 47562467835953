<template>
  <div class="DeviceDetail">
    <div class="deviceStatus">
      <div class="statusItem" v-for="item in status" :key="item.className">
        <div :class="item.className"></div>
        <div class="statusDetail">
          <div class="statusName">{{ item.name }}</div>
          <div class="statusValue">
            <span class="value">{{ deviceData[item.code] || 0 }}</span>
            台
          </div>
        </div>
      </div>
    </div>
    <div class="deviceIcon" :style="`background:url(${baseip + deviceDetail[deviceIndex].iconUrl}) no-repeat;`">
      <div class="Detail">
        <div class="deviceName" :title="deviceDetail[deviceIndex].deviceName">{{ deviceDetail[deviceIndex].deviceName }}</div>
        <div class="deviceStaus" :class="deviceDetail[deviceIndex].state + 'icon'">
          {{ deviceDetail[deviceIndex].state === 'running' ? '运行中' : deviceDetail[deviceIndex].state === 'offline' ? '离线中' : deviceDetail[deviceIndex].state === 'alarm' ? '报警中' : '空闲中' }}
        </div>
        <div class="deviceFactory" :title="deviceDetail[deviceIndex].factoryPath">{{ deviceDetail[deviceIndex].factoryPath }}</div>
      </div>
    </div>
    <div class="deviceParams" :style="deviceDetail[deviceIndex].list.length === 3 ? 'transform: translateX(123px) ' : ''">
      <div class="params" :class="deviceDetail[deviceIndex].list.length === 3 ? Threeparamslist[index].className : item.className" v-for="(item, index) in deviceDetail[deviceIndex].list" :key="item.className">
        <div class="paramsName">{{ item.name || '' }}</div>
        <div class="paramsValue" :title="item.value">
          <div class="valueNum">
            {{ item.value || '' }}
          </div>
          <div class="paramsUnit">{{ item.unit || '' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: [
        { isActive: true, name: '运行设备', className: 'running', code: 'runningDeviceSize' },
        { isActive: false, name: '空闲设备', className: 'free', code: 'freeDeviceSize' },
        { isActive: false, name: '故障设备', className: 'Alarm', code: 'alarmDeviceSize' },
        { isActive: false, name: '离线设备', className: 'offline', code: 'offlineDeviceSize' }
      ],
      paramslist: [{ className: 'left_1' }, { className: 'left_2' }, { className: 'center_0' }, { className: 'right_2' }, { className: 'right_1' }],
      Threeparamslist: [{ className: 'left_2' }, { className: 'center_0' }, { className: 'right_2' }],
      newparams: [],
      deviceData: null,
      timer: null,
      deviceDetail: [],
      deviceIndex: 0,
      allTimer: null,
      baseip: ''
    };
  },
  created() {
    this.baseip = process.env.VUE_APP_ICONIMG_URL;
    this.init();
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.allTimer);
  },
  methods: {
    async init() {
      clearInterval(this.timer);
      const deviceDetail = await this.$apis.LSD.getDevice();
      this.deviceDetail = deviceDetail;
      const deivceData = await this.$apis.LSD.deviceData();
      this.deviceData = deivceData;
      this.deviceDetail[this.deviceIndex].list.forEach((item, index) => {
        item.className = this.paramslist[index].className;
      });
      this.timer = setInterval(async () => {
        this.deviceIndex++;
        if (this.deviceIndex === this.deviceDetail.length) {
          this.init();
          this.deviceIndex = 0;
        }
        this.deviceDetail[this.deviceIndex].list.forEach((item, index) => {
          item.className = this.paramslist[index].className;
        });
        const deivceData = await this.$apis.LSD.deviceData();
        this.deviceData = deivceData;
      }, 30 * 1000);
    }
  }
};
</script>

<style scoped>
.DeviceDetail {
  position: relative;
  box-sizing: border-box;
  padding-top: 30px;
  height: 940px !important;
}
.deviceName {
  font-size: 16px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #f3f3f3;
}
.deviceStaus {
  position: relative;
  margin: 10px 0px;
  padding-left: 20px;
  font-size: 16px;
  color: #f3f3f3;
}
.deviceFactory {
  color: #f3f3f3;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.params {
  width: 140px;
  height: 108px;
  margin: 0px 10px;
}
.paramsName {
  color: #f3f3f3;
  width: 140px;
  height: 30px;
  margin-bottom: 10px;
  line-height: 30px;
  text-align: center;
}
.paramsValue {
  position: relative;
  display: flex;
  justify-content: center;
  color: #f3f3f3;
  height: 40px;
  width: 140px;
  line-height: 30px;
  text-align: center;
}
.valueNum {
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.paramsUnit {
  position: absolute;
  bottom: -8px;
  right: 6px;
  font-size: 10px;
}
.left_1 {
  position: relative;
  top: 60px;
  background: url('../../../assets/img/main/left_1.png') no-repeat;
}
.left_1 > .paramsName,
.left_1 > .paramsValue {
  transform: translateY(13px) rotateZ(8deg);
}
.left_2 {
  position: relative;
  top: 90px;
  background: url('../../../assets/img/main/left_2.png') no-repeat;
}
.left_2 > .paramsName,
.left_2 > .paramsValue {
  transform: translateY(3px) rotateZ(4deg);
}
.center_0 {
  position: relative;
  top: 103px;
  background: url('../../../assets/img/main/center_0.png') no-repeat;
}
.right_2 {
  position: relative;
  top: 90px;
  background: url('../../../assets/img/main/right_2.png') no-repeat;
}
.right_2 > .paramsName,
.right_2 > .paramsValue {
  transform: translateY(3px) rotateZ(-4deg);
}
.right_1 {
  position: relative;
  top: 55px;
  background: url('../../../assets/img/main/right_1.png') no-repeat;
}
.right_1 > .paramsName,
.right_1 > .paramsValue {
  transform: translateY(13px) rotateZ(-8deg);
}
.deviceParams {
  position: absolute;
  display: flex;
  margin-left: 70px;
  bottom: 235px;
  width: 100%;
  height: 200px;
}
.deviceIcon {
  position: relative;
  width: 100%;
  height: 550px;
  background-clip: inherit !important;
  background-position: 45% 60% !important;
}
.Detail {
  position: absolute;
  top: 12%;
  left: 6%;
  width: 350px;
  padding-top: 20px;
  padding-left: 20px;
  height: 156px;
  background: url('../../../assets/img/main/device_detail.png') no-repeat;
}
.Detail > .runningicon::before {
  position: absolute;
  left: 0;
  top: 8px;
  content: '';
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #5ebb38;
  box-shadow: rgba(94, 187, 56, 0.4) 0px 0px 6px 6px;
}
.Detail > .freeicon::before {
  position: absolute;
  left: 0;
  top: 8px;
  content: '';
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #f39800;
  box-shadow: rgba(243, 152, 0, 0.4) 0px 0px 6px 6px;
}
.Detail > .alarmicon::before {
  position: absolute;
  left: 0;
  top: 8px;
  content: '';
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #ea3837;
  box-shadow: rgba(234, 56, 55, 0.4) 0px 0px 6px 6px;
}
.Detail > .offlineicon::before {
  position: absolute;
  left: 0;
  top: 8px;
  content: '';
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #909399;
  box-shadow: rgba(144, 147, 153, 0.4) 0px 0px 6px 6px;
}
.deviceStatus {
  display: flex;
  justify-content: center;
}
.statusItem {
  display: flex;
  width: 180px;
  height: 60px;
}
.statusDetail {
  padding-left: 10px;
}
.statusName {
  color: #f3f3f3;
  font-size: 16px;
}
.statusValue {
  font-size: 10px;
  line-height: 30px;
  color: #f3f3f3;
}
.statusValue > .value {
  font-size: 28px;
  font-family: MyFontName;
}
.running {
  width: 60px;
  height: 52px;
  background: url('../../../assets/img/main/running.png') no-repeat;
  background-position: left center;
}
.free {
  width: 60px;
  height: 52px;
  background: url('../../../assets/img/main/free.png') no-repeat;
  background-position: left center;
}
.Alarm {
  width: 60px;
  height: 52px;
  background: url('../../../assets/img/main/Alarm.png') no-repeat;
  background-position: left center;
}
.offline {
  width: 60px;
  height: 52px;
  background: url('../../../assets/img/main/offline.png') no-repeat;
  background-position: left center;
}
</style>
