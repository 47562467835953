<template>
  <div class="weekPerfrom frame">
    <div class="perfromProgress" v-if="weekPerfrom">
      <el-progress class="oee" :stroke-width="4" :percentage="weekPerfrom.oee - 0"></el-progress>
      <el-progress class="orange" :stroke-width="4" :percentage="weekPerfrom.availability - 0"></el-progress>
      <el-progress class="green" :stroke-width="4" :percentage="weekPerfrom.quality - 0"></el-progress>
      <el-progress class="blue" :stroke-width="4" :percentage="weekPerfrom.performance - 0"></el-progress>
    </div>
    <div class="weekRunTime">
      <div class="bigCircle">
        <div v-for="(item, index) in statuslist" :key="item.className" :class="index === statusindex ? item.activeClass : item.className"></div>
        <div class="smallCircle">
          <div :class="statuslist[statusindex].textClass">{{ statuslist[statusindex].name }}</div>
          <div class="statusNum" :class="statuslist[statusindex].textClass">{{ `${(weekPerfrom[statuslist[statusindex].timerCode] / 60 / 1000 / 60).toFixed(1)}小时` }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      weekPerfrom: null,
      timer: null,
      statusindex: 0,
      statuslist: [
        { name: '运行时长', className: 'run', activeClass: 'runActive', textClass: 'runtext', timerCode: 'runningTime' },
        { name: '离线时长', className: 'off', activeClass: 'offActive', textClass: 'offtext', timerCode: 'offlineTime' },
        { name: '故障时长', className: 'alarm', activeClass: 'alarmActive', textClass: 'alarmtext', timerCode: 'alarmTime' },
        { name: '空闲时长', className: 'free', activeClass: 'freeActive', textClass: 'freetext', timerCode: 'freeTime' }
      ]
    };
  },
  created() {
    this.init();
    this.timer = setInterval(() => {
      this.init();
    }, 10 * 1000);
  },
  methods: {
    async init() {
      this.statusindex++;
      if (this.statusindex > 3) {
        this.statusindex = 0;
      }
      const weekPerfrom = await this.$apis.LSD.weekPerfrom();
      this.weekPerfrom = weekPerfrom;
    }
  }
};
</script>

<style scoped lang="scss">
.perfromProgress,
.weekRunTime {
  flex: 1;
}
.perfromProgress {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  padding-top: 12px;
}
/deep/ .el-progress--line {
  position: relative;
  width: 190px;
}
.oee::before {
  position: absolute;
  content: '设备效率';
  top: -14px;
  font-size: 14px;
  color: #fefefe;
}
.orange::before {
  position: absolute;
  content: '可用指数';
  top: -14px;
  font-size: 14px;
  color: #fefefe;
}
.green::before {
  position: absolute;
  content: '质量指数';
  top: -14px;
  font-size: 14px;
  color: #fefefe;
}
.blue::before {
  position: absolute;
  content: '表现指数';
  top: -14px;
  font-size: 14px;
  color: #fefefe;
}
/deep/ .el-progress-bar {
  width: 120%;
  margin-right: 0px;
}
/deep/ .el-progress-bar__outer {
  background-color: #082646;
  overflow: unset;
}
/deep/ .oee .el-progress-bar__inner {
  position: relative;
  background: linear-gradient(-90deg, #ffeb9d 0%, #fffbf6 100%);
}
.oee /deep/ .el-progress-bar__inner::after {
  position: absolute;
  content: '';
  right: -1px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #feea9d;
  border-radius: 50%;
}
.oee /deep/ .el-progress-bar__inner::before {
  position: absolute;
  content: '';
  right: -3px;
  top: -4px;
  width: 10px;
  height: 10px;
  border: 1px solid #feea9d;
  border-radius: 50%;
}
/deep/ .orange .el-progress-bar__inner {
  position: relative;
  background: linear-gradient(-90deg, #f39800 0%, #fdde68 100%);
}
.orange /deep/ .el-progress-bar__inner::after {
  position: absolute;
  content: '';
  right: -1px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #f39800;
  border-radius: 50%;
}
.orange /deep/ .el-progress-bar__inner::before {
  position: absolute;
  content: '';
  right: -3px;
  top: -4px;
  width: 10px;
  height: 10px;
  border: 1px solid #f39800;
  border-radius: 50%;
}
/deep/ .green .el-progress-bar__inner {
  position: relative;
  background: linear-gradient(-90deg, #5ebc38 0%, #90e66d 100%);
}
.green /deep/ .el-progress-bar__inner::after {
  position: absolute;
  content: '';
  right: -1px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #5ebc38;
  border-radius: 50%;
}
.green /deep/ .el-progress-bar__inner::before {
  position: absolute;
  content: '';
  right: -3px;
  top: -4px;
  width: 10px;
  height: 10px;
  border: 1px solid #5ebc38;
  border-radius: 50%;
}
/deep/ .blue .el-progress-bar__inner {
  position: relative;
  background: linear-gradient(-90deg, #438edb 0%, #78c4ff 100%);
}
.blue /deep/ .el-progress-bar__inner::after {
  position: absolute;
  content: '';
  right: -1px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #438edb;
  border-radius: 50%;
}
.blue /deep/ .el-progress-bar__inner::before {
  position: absolute;
  content: '';
  right: -3px;
  top: -4px;
  width: 10px;
  height: 10px;
  border: 1px solid #438edb;
  border-radius: 50%;
}
/deep/ .el-progress__text {
  position: absolute;
  top: -12px;
  right: 2px;
  color: #fefefe;
}
</style>
<style scoped>
.weekRunTime {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../../assets/img/defaultimg/border.png') no-repeat;
  background-position: 50% 50%;
}
.bigCircle {
  position: relative;
  width: 158px;
  height: 158px;
  border-radius: 50%;
  border: 1px dotted rgba(67, 186, 254, 0.5);
}
.run {
  position: absolute;
  right: 33%;
  top: -25px;
  width: 48px;
  height: 48px;
  background: url('../../../assets/img/defaultimg/run_none.png') no-repeat;
}
.runActive {
  position: absolute;
  right: 33%;
  top: -25px;
  width: 48px;
  height: 48px;
  background: url('../../../assets/img/defaultimg/run_active.png') no-repeat;
}
.off {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: url('../../../assets/img/defaultimg/off_none.png') no-repeat;
}
.offActive {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: url('../../../assets/img/defaultimg/off_active.png') no-repeat;
}
.alarm {
  position: absolute;
  right: 32%;
  bottom: -30px;
  width: 48px;
  height: 48px;
  background: url('../../../assets/img/defaultimg/alarm_none.png') no-repeat;
}
.alarmActive {
  position: absolute;
  right: 32%;
  bottom: -30px;
  width: 48px;
  height: 48px;
  background: url('../../../assets/img/defaultimg/alarm_active.png') no-repeat;
}
.free {
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: url('../../../assets/img/defaultimg/free_none.png') no-repeat;
}
.freeActive {
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: url('../../../assets/img/defaultimg/free_active.png') no-repeat;
}
.smallCircle {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 104px;
  height: 104px;
  border: 1px solid #1bb1bd;
  border-radius: 50%;
}
.runtext {
  color: #5ebb38;
}
.offtext {
  color: #7e7e7e;
}
.alarmtext {
  color: #e43737;
}
.freetext {
  color: #f39800;
}
.statusNum {
  font-size: 16px;
  font-family: MyFontName;
}
</style>
