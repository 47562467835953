import { render, staticRenderFns } from "./DataMoudle.vue?vue&type=template&id=70ab1926&scoped=true&"
import script from "./DataMoudle.vue?vue&type=script&lang=js&"
export * from "./DataMoudle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ab1926",
  null
  
)

export default component.exports