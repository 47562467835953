<template>
  <div class="deviceCloud frame">
    <div class="sumDevcie">
      <div class="num">
        <span>{{ deviceData.totalDeviceSize }}</span>
        <span class="text">台</span>
      </div>
      <div class="radar">
        <div class="semiCircle">
          <div class="smallSemiCircle">
            <div class="Circle">
              <div class="dot"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="chartsText">上云设备</div>
    </div>
    <!-- 在线 -->
    <div class="onDevice">
      <div class="num">
        <span>{{ deviceData.onlineDeviceSize }}</span>
        <span class="text">台</span>
      </div>
      <div class="charts onCharts">
        <el-progress :width="90" :show-text="false" :stroke-width="6" type="circle" :percentage="deviceData.onlineDeviceRate - 0" color="#f39800"></el-progress>
      </div>
      <div class="onRate">{{ deviceData.onlineDeviceRate }}%</div>
      <div class="chartsText">在线设备</div>
    </div>
    <!-- 离线 -->
    <div class="offDevice">
      <div class="num">
        <span>{{ deviceData.offlineDeviceSize }}</span>
        <span class="text">台</span>
      </div>
      <div class="charts offCharts">
        <el-progress :width="90" :show-text="false" :stroke-width="6" type="circle" :percentage="deviceData.offlineDeviceRate - 0" color="#b9b9b9"></el-progress>
      </div>
      <div class="offRate">{{ deviceData.offlineDeviceRate }}%</div>
      <div class="chartsText">离线设备</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deviceData: {}
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const deivceData = await this.$apis.LSD.deviceData();
      this.deviceData = deivceData;
      setInterval(async () => {
        const deivceData = await this.$apis.LSD.deviceData();
        this.deviceData = deivceData;
      }, 10 * 1000);
    }
  }
};
</script>

<style scoped>
.charts >>> svg path:first-child {
  stroke: #0b4f70;
}
.radar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  border: 1px solid #57bbdd;
  margin-bottom: 26px;
}
.radar::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: url('../../../assets/img/defaultimg/left_cross.png') no-repeat;
  background-position: 50% 50%;
}
.semiCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  border-top: 1px solid #57bbdd;
  border-left: 1px solid #57bbdd;
  border-bottom: 1px solid #57bbdd;
  border-right: 1px solid transparent;
  animation: rotate 5s infinite linear;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.smallSemiCircle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 57px;
  border-radius: 50%;
  border: 1px solid #57bbdd;
}
.smallSemiCircle::before {
  content: '';
  position: absolute;
  top: 0;
  width: 54px;
  height: 53px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top: 3px solid #57bbdd;
  border-left: 3px solid #57bbdd;
  animation: reversal 5s infinite linear;
}
@keyframes reversal {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
.Circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #57bbdd;
  animation: reversal 5s infinite linear;
  background-color: #07253f;
  overflow: hidden;
}
.dot {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #57bbdd;
  animation: offsetRotate 5s infinite linear;
}
.dot::after {
  position: absolute;
  content: '';
  top: 5px;
  left: -6px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #26f4fa;
  /* border-image: linear-gradient(#26f4fa, #0b4d63); */
  animation: offsetRotate 5s infinite linear;
  transform-origin: top center;
}
@keyframes offsetRotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0.8;
  }
}
</style>
